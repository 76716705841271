import logo from './logo.png';
import './App.css';

import { FaAlignLeft ,FaAlignJustify,FaChartLine,FaCode ,FaCubes,FaBullhorn  ,FaProjectDiagram   ,FaDiceD20  ,FaLaptopCode , FaRocket,FaMapMarkedAlt  ,FaUserCircle , FaUserTie  } from "react-icons/fa";


import { AiOutlineMail , AiTwotonePhone  } from "react-icons/ai";


function App() {
  return (
    <div className='w-full overflow-hidden bg-slate-100'>
      {/* Head  */}
    <div className="bg-gradient-to-tl  from-purple-500 to-pink-500">
      {/* NAvbar start */}
          <div className='flex lg:space-x-8 justify-between lg:p-8 p-4 md:p-6'>
            <div><img src={logo} className='lg:w-56 lg:h-20 w-36 h-16'  alt="logo"/></div>
            <div  className='lg:block hidden'>
              <ul className='flex lg:space-x-8 lg:mt-4 lg:mr-4 font-bold text-xl'>
                <li className='hover:text-primaryColor'>
                  Home
                </li>
                <li className='hover:text-primaryColor'>
                  About
                </li>
                <li className='hover:text-primaryColor'>
                  Blog
                </li>
                <li className='hover:text-primaryColor'>
                  Contact Us
                </li>
              </ul>
            </div>

            <div className='lg:hidden'>
              <FaAlignJustify  className='text-3xl md:text-4xl mt-4 mr-2 text-primaryColor'/>
            </div>
          </div>

          {/*  slider text section */}
          <div> 
            <h1 className='lg:text-9xl text-3xl font-semibold md:text-4xl md:font-bold lg:font-extrabold text-white p-4 md:p-6 lg:p-8 mt-4 lg:mt-8'>Take your business to the next level</h1>
            <h1 className='lg:text-4xl lg:font-bold text-xl text-primaryColor lg:p-8 md:p-6 p-4'>With our Quality Digital Solutions</h1>
          </div>

          {/*  slider next section */}
          <div>

          </div>




    </div>

    {/* About Us */}
    
    <div className='lg:flex lg:p-8 md:p-6 p-4 w-full lg:mt-12 md:mt-10 mt-8'>

      <div className='lg:w-1/4'>
          <h1 className='text-4xl font-semibold underline underline-offset-2 text-primaryColor'>About Us
      </h1>
      
      </div>
      <div className='lg:w-3/4 mt-4'>
        <p>

        We are a new-age tech company which thrives on creativity, intuition and determination. Our premium services are quick, lean and market-focused. We can solve your business related issues and take your business to the next level with our digital solutions tailored to suit all your needs.

       <br/> You can grow your business with our help because our mantra is “What helps people, helps business”.

      <br/> We love leveraging our digital tech expertise to help grow your business with our unique work processes because, “Success is liking what we do and how we do it”.

        </p>
      </div>
    </div>


    {/*  Offers */}

    <div className='lg:flex w-full space-y-4 lg:space-y-0 p-4 lg:p-8 mt-8'>
      <div className='lg:w-1/3 p-4'>
        <FaDiceD20  className='text-8xl'/>
        <h1 className='mt-2 text-2xl font-semibold text-primaryColor'>We Are Creative</h1>
        <h1 className='mt-2 text-lg text-slate-500'>For us Creativity is making every detail perfect and limiting the number of details to perfect!!</h1>
      </div>

      <div className='lg:w-1/3 p-4'>
        <FaRocket  className='text-8xl'/>
        <h1 className='mt-2 text-2xl font-semibold text-primaryColor'>We Are Innovative</h1>
        <h1 className='mt-2 text-lg text-slate-500'>Our Secret to Innovation is focusing all our energy on building the new rather than changing the old.</h1>
      </div>

      <div className='lg:w-1/3 p-4'>
        <FaUserTie  className='text-8xl'/>
        <h1 className='mt-2 text-2xl font-semibold text-primaryColor'>We Are Reliable</h1>
        <h1 className='mt-2 text-lg text-slate-500'>We promise you an experience you can trust and service you always can count on.</h1>
      </div>

    </div>


    {/*  services */}

    <div className='lg:flex lg:p-8 md:p-6 p-4 w-full lg:mt-12 md:mt-10 mt-8'>

<div className='lg:w-1/4'>
    <h1 className='text-4xl font-semibold underline underline-offset-2 text-primaryColor'>Our Services
</h1>

</div>
<div className='lg:w-3/4 mt-4'>
  <p>

  Our premium services are quick, lean and market-focused. We can solve your business related issues and take your business to the next level with our digital solutions tailored to suit all your needs. Once you get connected with us; Rest assured you have got yourself a long term reliable digital solutions partner who’s fully committed to growing your business, this unparalleled level of commitment helps us in driving the complete digital strategy as our own, which in turn ensures we put our best foot forward everytime.

  </p>
</div>
</div>

{/*  deals */}

<div className='px-8 py-12 mt-4 lg:grid lg:grid-cols-2 lg:gap-y-12 lg:gap-x-6 space-y-8 lg:space-y-0'>

<div className=' flex lg:space-x-8  space-x-8'>

<FaLaptopCode  className='lg:text-5xl text-primaryColor  text-3xl'/>
<h1 className='lg:text-4xl text-2xl font-semibold hover:text-primaryColor'>website Development</h1>

</div>

<div className=' flex lg:space-x-8 space-x-8'>

<FaChartLine  className='lg:text-5xl text-primaryColor text-3xl'/>
<h1 className='lg:text-4xl text-2xl font-semibold  hover:text-primaryColor'>Digital Markeeting</h1>

</div>

<div className='  flex lg:space-x-8 space-x-8'>

<FaBullhorn  className='lg:text-5xl  text-primaryColor text-3xl'/>
<h1 className='lg:text-4xl text-2xl font-semibold  hover:text-primaryColor'>Branding Solutions</h1>

</div>

<div className=' flex lg:space-x-8 space-x-8'>

<FaProjectDiagram  className='lg:text-5xl text-primaryColor  text-3xl'/>
<h1 className='lg:text-4xl text-2xl font-semibold  hover:text-primaryColor'>Design Solutions</h1>

</div>

<div className='  flex lg:space-x-8 space-x-8'>

<FaCode  className='lg:text-5xl text-primaryColor  text-3xl'/>
<h1 className='lg:text-4xl text-2xl font-semibold  hover:text-primaryColor'>Mobile Apps Development</h1>

</div>

<div className='flex lg:space-x-6 space-x-8'>

<FaCubes  className='lg:text-5xl text-primaryColor  text-3xl'/>
<h1 className='lg:text-4xl text-2xl font-semibold  hover:text-primaryColor'>Custom Software Development</h1>

</div>

</div>
{/*  deals end */}

{/*  Contact Us */}

<div className='bg-gradient-to-tl mt-4 py-12 px-4 lg:px-8 from-primaryColor to-pink-500 lg:flex w-full'>

<div className='lg:w-2/3'>

<h1 className='lg:text-xl text-lg font-medium'>Take your business to the next level</h1>
<h1 className='lg:text-6xl mt-2 text-2xl font-bold'>

Have a project for us? <br/>
Get in touch!
</h1>
</div>
<div className='lg:w-1/3'>

<h1 className='text-center text-2xl font-bold text-white mt-8 lg:mt-12 shadow-md p-2'>+14072336850 <br/> Contact us now</h1>


</div>



</div>

{/*  contact details */}

<div className='lg:flex space-y-6 lg:space-y-0 py-8 lg:py-12 px-4 lg:space-x-8  shadow-lg'>


<div className='lg:w-1/3'>
<AiTwotonePhone  className='mx-auto text-4xl text-primaryColor'/>
<h1 className='text-center text-xl font-bold mt-2'>+14072336850</h1>
</div>

<div className='lg:w-1/3'>
<AiOutlineMail  className='mx-auto text-4xl text-primaryColor'/>
<h1 className='text-center text-xl font-bold mt-2'>info@infinitytechllc.net</h1>
</div>

<div className='lg:w-1/3'>
<FaMapMarkedAlt  className='mx-auto text-4xl text-primaryColor'/>
<h1 className='text-center text-xl font-bold mt-2'>272 glowing peace ln  <br/>
orlando florida 
32824</h1>
</div>

</div>

{/*  Footer */}

<div className='text-primaryColor px-4 font-bold py-2'>
<p>infinitytechllc  |  All rights reserved. </p>
</div>

  </div>
  );
}

export default App;
